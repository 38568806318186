import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'gatsby';

import './scss/index.scss';

function House({houseData, className}) {
    return (
        <div className={`equipment-house ${className ? className : ""}`}>
            <div className="equipment-house-wrapper" id={'modal-header-' + houseData.node.slug}>
                <img src={houseData.node.acf.icon.localFile.publicURL} alt={houseData.node.title}/>
            </div>
            <div className="equipment-house-text">
                <h3 className="equipment-house-title">{houseData.node.title}</h3>
                <p className="equipment-house-space">{houseData.node.acf.space + 'm²'}</p>
                <p className="equipment-house-people">{houseData.node.acf.people + ' osób'}</p>
            </div>
            <Link to={`/budynki/${houseData.node.slug}#calendar`} className="btn btn-primary btn-sm mt-3">Rezerwuj</Link>
        </div>
    );
}

House.propTypes = {
    houseData: PropTypes.object.isRequired,
    className: PropTypes.string
}


export default House;