import React from 'react';
import PropTypes from "prop-types";
import SEO from '../seo';

import './scss/index.scss';

const pageHero = ({header, fullwidth, fullheight, background, className, children, post, homepage, icon}) => (
    <>
        <SEO title={header} />
        <section className={`hero ${className ? className : ''} ${fullheight ? 'fullheight' : ''} ${post ? 'post' : ''} ${homepage ? 'pb-0' : ''}`} style={{ backgroundImage: background ? `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${background})` : `` }}>
            <div className={`${fullwidth ? 'container-fluid px-3' : 'container'} h-100 `}>
              <div className="d-flex flex-column h-100 ">
                {icon ? (
                  <img className="hero-icon" src={icon} alt=""/>
                ) : null}
                {header ? <h1 className="hero-header">{header}</h1> : ''}
                {post ? '' : children}
              </div>
            </div>
        </section>
    </>
)

pageHero.propTypes = {
    header: PropTypes.node,
    fullwidth: PropTypes.bool,
    background: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
}

export default pageHero